<template>
  <Multiselect
      class="form-control"
      v-model="selected_company"
      :searchable="true"
      :hideSelected="true"
      :options="companies"
      placeholder="Select company"
      @search-change="getOptions($event)"
      :object="true"
      @input="onOptionSelect"
      :caret="true"
  >
  </Multiselect>

</template>

<script>
import "@vueform/multiselect/themes/default.css";
import Multiselect from "@vueform/multiselect";
import axios from "axios";


export default {
  name: 'SelectProduct',
  emits: ['onSelect'],
  props: {
    current_company: {
      type: Object,
      default: () => {
      },
    }
  },
  data() {
    return {
      companies: [],
      selected_company: null,
    }
  },
  methods: {
    async getOptions(query) {
      if (query.length <= 2) return;
      let companies = (await axios.get(`/customer/companies/?limit=6&search=${query}`)).data.results
      this.selected_company = null
      this.companies = companies.map((company) => {
        return {
          value: company.id,
          label: company.name,
          address: company.address,
          slug: company.slug,
        }
      })
    },
    onOptionSelect(event) {
      this.$emit('onSelect', event === null ? null : event)
    },
    unselectCompany() {
      this.selected_company = null
    }
  },
  components: {
    Multiselect,
  },
  mounted() {
    if (this.current_company && Object.keys(this.current_company).length > 0) {
      this.selected_company = {
        value: this.current_company.id,
        label: this.current_company.name,
      }
      this.companies = [this.selected_company]
    } else {
      this.selected_company = null
      this.companies = []
    }
  },
  watch: {
    current_company(newVal) {
      if (newVal && Object.keys(newVal).length > 0) {
        this.selected_company = {
          value: newVal.id,
          label: newVal.name,
        }
        this.companies = [this.selected_company]
      } else {
        this.selected_company = null
        this.companies = []
      }
    }
  }
}

</script>

<style scoped>

</style>